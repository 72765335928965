@if (control?.invalid && control?.touched) {
  <div class="text-error">
    @if (control?.errors.required) {
      <div class="field-error">{{ name }} is required</div>
    }
    @if (control?.errors.email) {
      <div class="field-error">{{ name }} is incorrect</div>
    }
    @if (control?.errors.error) {
      <div class="field-error">
        {{ control.errors.error }}
      </div>
    }
  </div>
}
