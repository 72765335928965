import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'ess-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  standalone: true,
})
export class FormErrorComponent {
  @Input({ required: true }) control!: UntypedFormControl;
  @Input() name = null;
}
