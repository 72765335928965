import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IOption } from 'src/app/core/http/http-response.model';
import { environment } from '@environment';
import { HttpListResponse } from '@ess-front/shared';

@Injectable()
export class AutocompleteService {
  apiURL = environment.apiEnv;
  constructor(private readonly httpClient: HttpClient) {}

  getSearch$(endpoint: string, search = ''): Observable<HttpListResponse<IOption>> {
    const url = `${this.apiURL}${endpoint}&search=${search}`;
    return this.httpClient.get<HttpListResponse<IOption>>(url);
  }
}
