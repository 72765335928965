<section>
  <mat-form-field class="full-width" [appearance]="appearance">
    <input
      type="text"
      [required]="isRequired"
      [formControl]="control"
      matInput
      [matAutocomplete]="auto"
      [placeholder]="placeholder"
      (blur)="onBlurEvent()"
      [attr.aria-label]="placeholder"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectEvent($event)" [displayWith]="displayFn">
      @for (option of filteredOptions$ | async; track option) {
        <mat-option [value]="option">
          {{ option.label }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>

  @if (control.errors) {
    <ess-form-error [control]="control" [name]="label"></ess-form-error>
  }
</section>
