import { Component, DestroyRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take } from 'rxjs/operators';
import { AutocompleteService } from 'src/app/shared/autocomplete/services/autocomplete.service';
import { IOption } from 'src/app/core/http/http-response.model';
import {
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from '@shared/form-error/form-error.component';

@Component({
  selector: 'ess-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FormErrorComponent,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
    AutocompleteService,
  ],
})
export class AutocompleteComponent implements ControlValueAccessor {
  @Input({ required: true }) endpoint!: string;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() appearance = 'fill';
  @Input() selectSearch = false;
  @Input() emitSelection = false;
  @Input() errors = null;
  @Input() isRequired = false;
  @Input() isDisabled = false;
  @Input() multiple = false;

  @Output() eventSelect = new EventEmitter<IOption>();
  @Output() searchChange = new EventEmitter<string>();
  @Output() blurred = new EventEmitter();

  filteredOptions$: Observable<IOption[]>;
  control = new UntypedFormControl();
  value: string | IOption | null = null;
  options: IOption[] = [];
  onChangeCb?: (value: string | IOption) => void;
  onTouchedCb?: () => void;

  constructor(
    private autocompleteService: AutocompleteService,
    private destroyRef: DestroyRef,
  ) {
    this.filteredOptions$ = this.initFilteredOptions$();
  }

  initFilteredOptions$(): Observable<IOption[]> {
    return this.control.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        return this.onSearchEvent$(val || '');
      }),
    );
  }

  onBlurEvent() {
    this.onTouchedCb?.();
    this.blurred.emit();
    if (typeof this.control.value === 'string') {
      if (!this.selectSearch) {
        this.resetFormStatus();
        return;
      }

      this.onChangeCb?.(this.control.value);
      if (this.emitSelection) this.searchChange.emit(this.control.value ?? '');
    }
  }

  displayFn(option: IOption): string {
    return option?.label ?? '';
  }

  onSearchEvent$(search: string): Observable<IOption[]> {
    return this.autocompleteService.getSearch$(this.endpoint, search).pipe(
      take(1),
      map(x => this.transformModelIntoIOption(x.results)),
    );
  }

  transformModelIntoIOption(arr: Array<any>): IOption[] {
    return arr.map(element => {
      if (element.label && element.value) return element;
      return {
        label: element.title,
        value: element.id,
      };
    });
  }

  onSelectEvent(event: MatAutocompleteSelectedEvent): void {
    this.value = event.option.value ?? null;
    if (!this.value) return;
    if (this.emitSelection) {
      this.eventSelect.emit(event.option.value);
      this.resetFormStatus();
    }
    this.onChangeCb?.(this.value);
  }

  writeValue(value: string): void {
    this.value = value || null;
    if (!this.value) {
      this.control.reset();
      this.control.setErrors(null);
    }
    this.control.setValue(this.value, { emitEvent: false });
  }

  registerOnChange(fn: (value: string | IOption) => void): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCb = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    isDisabled ? this.control.disable() : this.control.enable();
  }

  resetFormStatus() {
    this.control.reset();
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
  }
}
